import { useState, useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { createClient } from '@supabase/supabase-js'

// Lazy load components
const Auth = lazy(() => import('./components/Auth'))
const Profile = lazy(() => import('./components/Profile'))
const Landing = lazy(() => import('./pages/Landing'))
const Home = lazy(() => import('./pages/Home'))
const Game = lazy(() => import('./pages/Game'))
const AuthCallback = lazy(() => import('./pages/AuthCallback'))

// Create Supabase client
export const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL || '',
  import.meta.env.VITE_SUPABASE_ANON_KEY || '',
  {
    auth: {
      persistSession: true,
      storageKey: 'disc-duel-auth',
    },
  }
)

function App() {
  const [session, setSession] = useState<any>(null)
  const [hasProfile, setHasProfile] = useState<boolean | null>(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      if (session) {
        checkProfile(session)
      }
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      if (session) {
        checkProfile(session)
      } else {
        setHasProfile(null)
      }
    })

    return () => subscription.unsubscribe()
  }, [])

  const checkProfile = async (session: any) => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single()

      if (error) {
        if (error.code === 'PGRST116') {
          setHasProfile(false)
          return
        }
        console.error('Error checking profile:', error)
        return
      }

      setHasProfile(!!data)
    } catch (error) {
      console.error('Error checking profile:', error)
    }
  }

  return (
    <Router>
      <Box minH="100vh">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {!session ? (
              <>
                <Route path="/" element={<Landing />} />
                <Route path="/auth" element={<Auth />} />
                <Route path="/auth/callback" element={<AuthCallback />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            ) : !hasProfile ? (
              <>
                <Route path="/profile" element={<Profile session={session} />} />
                <Route path="*" element={<Navigate to="/profile" />} />
              </>
            ) : (
              <>
                <Route path="/home" element={<Home session={session} />} />
                <Route path="/game/:id" element={<Game session={session} />} />
                <Route path="/profile" element={<Profile session={session} />} />
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="*" element={<Navigate to="/home" />} />
              </>
            )}
          </Routes>
        </Suspense>
      </Box>
    </Router>
  )
}

export default App
