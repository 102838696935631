import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { ChakraProvider, Center, Spinner } from '@chakra-ui/react'
import App from './App'

// Loading fallback component
const LoadingFallback = () => (
  <Center h="100vh">
    <Spinner size="xl" color="blue.500" thickness="4px" />
  </Center>
)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ChakraProvider>
      <Suspense fallback={<LoadingFallback />}>
        <App />
      </Suspense>
    </ChakraProvider>
  </React.StrictMode>
)
